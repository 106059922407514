<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/username.png" class="icon" />
            <input type="text" v-model="username" class="input" placeholder="请输入姓名" readonly/>
          </div>
          <div class="item">
            <img src="@/assets/sj_1.png" class="icon" />
            <input type="number" v-model="tel" class="input" placeholder="请输入手机号码" />
          </div>
          <div class="item">
            <img src="@/assets/yzm.png" class="icon" />
            <input type="number" v-model="code" class="input" placeholder="请输入验证码" />
            <div class="checkcode" @click="getCode">{{ time === 0?'获取验证码': '获取验证码('+time+'s)' }}</div>
          </div>
        </div>
        <div class="button primary mt" @click="submit">注册</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  timer: '',
  data() {
    return {
      username: '',
      tel: '',
      unionid: '',
      openid:'',
      certificate_code: '',
      jmcert: '',
      code: '',     
      state:false,
      time: 0
    };
  },
  created() {
    this.username = this.$route.query.username;
    this.certificate_code = this.$route.query.certificate_code;
    this.jmcert = this.$route.query.code;
    this.tel = this.$route.query.mobile;
    this.unionid = this.$route.query.unionid;
    this.openid = this.$route.query.openid;
    window.sessionStorage.setItem('openid', this.openid); 
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    async getCode() {
      if (this.time !== 0) {
        return;
      }
      if (!isTel(this.tel)) {
        Toast('电话号格式不正确!');
        return;
      }
      let { data } = await this.axios.post('/ctid/JY_ITlService/jy_Mobile', qs.stringify({ phone: this.tel }));
      if (data.flag === '0') {
        Toast('发送成功!');
        window.clearInterval(this.timer);
        this.time = 60;
        this.timer = window.setInterval(() => {
          this.time = this.time - 1;
          if (this.time <= 0) {
            this.time = 0;
            window.clearInterval(this.timer);
          }
        }, 1000);
      } else {
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.username)) {
        Toast('用户名不可以为空!');
        this.state = false;
        return;
      }
      if (!isTel(this.tel)) {
        Toast('电话号格式不正确!');
        this.state = false;
        return;
      }
      if (isEmpty(this.code)) {
        Toast('验证码不可以为空!');
        this.state = false;
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/JY_ITlService//jy_MobileReg',
        qs.stringify({ phone: this.tel, mobilecode: this.code, username: this.username, unionid: this.unionid })
      );
      if (data.flag === '0') {
        this.state = false;
        Toast(data.message);
        this.$router.replace('/refueling/my?openid=' + this.openid);
      } else {
        this.state = false;
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.checkcode {
  position: absolute;
  line-height: 44px;
  height: 44px;
  right: 0;
  top: 35px;
  color: rgba(0, 49, 255, 1);
}
</style>